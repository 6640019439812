'use client';

import { useEffect, useLayoutEffect, useState } from 'react';
import { pascalCaseToSpacedLowerCase } from 'src/utils/strings';
import { getAnonymousId } from 'src/services/analytics-service';
import { useComponentContext } from 'src/components/layout/ComponentContext';
import { stubAnalyticsMethod } from 'lib/segment';

const useDeeplinkUrl = deepLinkUrlBase => {
  const deeplinkUrl = new URL(deepLinkUrlBase);
  const [parameterizedUrl, setParameterizedUrl] = useState(deeplinkUrl.toString());
  const { name, sectionIndex } = useComponentContext();
  const transformedName = pascalCaseToSpacedLowerCase(name);

  useEffect(() => {
    const params = {
      af_sub2: window.location.pathname,
      af_sub3: transformedName,
      af_sub4: sectionIndex?.toString(),
    };

    Object.entries(params)
      .filter(([_key, value]) => value)
      .forEach(([key, value]) => deeplinkUrl.searchParams.set(key, value));

    setParameterizedUrl(deeplinkUrl.toString());
  }, []);

  /**
   * Attempt to get the user's anonymousId from Segment
   * */
  const appendUidToCtaUrl = () => {
    window.analytics.ready(() => {
      const anonymousId = getAnonymousId();

      if (anonymousId) {
        deeplinkUrl.searchParams.set('af_sub1', anonymousId);
        setParameterizedUrl(deeplinkUrl.toString());
      }
    });
  };

  useLayoutEffect(() => {
    if (!window?.analytics?.ready) {
      stubAnalyticsMethod('ready');
    }

    appendUidToCtaUrl();
  }, []);

  return parameterizedUrl;
};

export { useDeeplinkUrl };
