import React, { useState, useEffect, useLayoutEffect, useCallback, useRef } from 'react';
import { classNames } from 'src/utils/css';
import { useHeaderContext } from 'src/components/layout/Header';
import { useTranslation } from 'src/components/contexts/TranslationContext';
import { getCTALinkButton } from 'src/components/common/CTALinkButton';
import { Logo } from 'src/components/common/Logo/Logo.component';
import { COMPONENT_NAMES, EVENT_NAMES, trackAnalyticsEvent } from 'src/services/analytics-service';
import {
  navHasLowerDeck_,
  upperDeck_,
  logoContainer_,
  mobileCta_,
  hideLogoMobile_,
  hideMobileCta_,
  mobileNavContainer_,
  upperDeckCtaContainer_,
  upperDeckCtaContainerContent_,
  lowerDeck_,
  desktopNavOpen_,
  desktopNavLinksListPrimary_,
  desktopNavLinksListSecondary_,
  desktopInteractiveListItem_,
  deckContent_,
  desktopNavLinkContainer_,
} from './Navigation.module.scss';
import { NavLink, NavDropdown, MobileNav } from './subcomponents';

export const Navigation = ({
  links,
  linksSecondary,
  primaryCta,
  secondaryCta,
  renderLogo,
  children,
}) => {
  const navRef = useRef();
  const lowerDeckRef = useRef();

  const {
    setIsMobileNavOpen,
    isDesktopNavOpen,
    setIsDesktopNavOpen,
    hasScrolledPastFirstSection,
  } = useHeaderContext();

  const isValidPrimaryCta = cta =>
    cta !== 'none' &&
    (typeof cta === 'object' && !!Object.keys(cta).length) &&
    (React.isValidElement(cta) || !!cta.href);

  const isValidSecondaryCta = cta =>
    cta !== 'none' && (typeof cta === 'object' && !!Object.keys(cta).length) && !!cta.href;

  const [activeIndex, setActiveIndex] = useState(null);
  const [mobileActiveIndex, setMobileActiveIndex] = useState();
  const hasPrimaryCta = isValidPrimaryCta(primaryCta);
  const hasSecondaryCta = isValidSecondaryCta(secondaryCta);

  /*
   * Resets all secondary navigation
   */
  const resetDesktopMenu = () => {
    setActiveIndex(null);
  };

  /*
   * Toggles secondary navigation on click of NavDropdown, or opens a new dropdown
   * if a dropdown other than the currently opened one is clicked
   *
   * @param {MouseEvent} The click event on a NavDropdown that triggered this callback
   */
  const handleDropdownClick = event => {
    const clickedDesktopIndex = event.currentTarget?.getAttribute('data-navindex');
    const clickedMobileIndex = event.currentTarget?.getAttribute('data-mobilenavindex');
    if (clickedDesktopIndex) {
      const clickedIndexInt = parseInt(clickedDesktopIndex, 10);
      const newIndex = clickedIndexInt === activeIndex ? null : clickedIndexInt;
      setActiveIndex(newIndex);
      trackAnalyticsEvent(EVENT_NAMES.NAV_DROPDOWN_OPENED, {
        label: event.currentTarget.textContent,
        component_name: COMPONENT_NAMES.DESKTOP_NAV,
      });
    } else if (clickedMobileIndex) {
      // We also pass event for the dropdown "Back" button, which we give a
      // unique index that won't  truthy with any drop down button indices.
      if (clickedDesktopIndex === 'backButton') {
        // ensures that all NavDropdowns are closed
        setMobileActiveIndex(-999);
        trackAnalyticsEvent(EVENT_NAMES.NAV_DROPDOWN_BACK, {
          label: event.currentTarget.textContent,
          component_name: COMPONENT_NAMES.MOBILE_NAV,
        });
        return;
      }

      const clickedIndexInt = parseInt(clickedMobileIndex, 10);
      const newIndex = clickedIndexInt === mobileActiveIndex ? null : clickedIndexInt;
      setMobileActiveIndex(newIndex);
      trackAnalyticsEvent(EVENT_NAMES.NAV_DROPDOWN_OPENED, {
        label: event.currentTarget.textContent,
        component_name: COMPONENT_NAMES.MOBILE_NAV,
      });
    }
  };

  /*
   * Closes all subnav menus when focus moves outside the nav
   *
   * @param {FocusEvent} The blur event on the Navigation element that triggered this callback
   */
  const handleNavBlur = event => {
    if (event.relatedTarget == null) {
      resetDesktopMenu();
    }
  };

  /*
   * Closes subnavs and mobile navs when the escape key is pressed
   *
   * @param {KeyboardEvent} The document-level keyboard event that triggered this callback
   */
  const handleKeyPress = event => {
    if (event.key === 'Escape') {
      resetDesktopMenu();
      setIsMobileNavOpen(false);
    }
  };

  const hasMobileNav = !!links.length;
  const hasLowerDeck = linksSecondary?.length > 0;

  const setNavCssVars = useCallback(() => {
    const navHeight = navRef.current.offsetHeight;
    const lowerDeckHeight = hasLowerDeck ? lowerDeckRef.current.offsetHeight : 0;
    document.documentElement.style.setProperty('--nav-height-px', `${navHeight}px`);
    document.documentElement.style.setProperty('--nav-lower-deck-height-px', `${lowerDeckHeight}px`);
  }, [hasLowerDeck]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress, false);

    return () => {
      document.removeEventListener('keydown', handleKeyPress, false);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /*
   * Logic for handling the screen height available
   */

  useEffect(() => {
    setNavCssVars();
    window.addEventListener('resize', setNavCssVars);

    return () => {
      window.removeEventListener('resize', setNavCssVars);
    };
  }, [setNavCssVars, hasLowerDeck]);

  /*
   * All of the logic for showing & hiding the dekstop nav on scroll
   */

  useLayoutEffect(() => {
    let lastScrollTop = 0;
    let lastDirection = 0;
    let currentScrollTop;
    let currentDirection;
    let scrollTop;

    const handleScroll = () => {
      scrollTop = document.documentElement.scrollTop;
      currentScrollTop = scrollTop;
      currentDirection = currentScrollTop > lastScrollTop ? 1 : 0;

      if (hasScrolledPastFirstSection && currentDirection !== lastDirection) {
        lastDirection = currentDirection;
        if (activeIndex !== null) return;
        if (scrollTop < lastScrollTop) {
          setIsDesktopNavOpen(true);
        } else {
          setIsDesktopNavOpen(false);
        }
      }

      lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop; // mobile safari
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex, hasScrolledPastFirstSection]);

  const assignDesktopInteractionType = (item, id, index) => {
    return item.categories ? (
      <NavDropdown
        label={item.label}
        categoryHeading={item.categories.introduction.heading}
        categorySubheading={item.categories.introduction.subheading}
        categoryHref={item.categories.introduction.href}
        categoryColumns={item.categories.columns}
        isOpen={index === activeIndex}
        data-navindex={index}
        id={id}
        onClick={handleDropdownClick}
      />
    ) : (
      <div className={desktopNavLinkContainer_}>
        <NavLink href={item.href}>{item.label}</NavLink>
      </div>
    );
  };

  return (
    <nav
      role="navigation"
      onMouseLeave={resetDesktopMenu}
      onBlur={handleNavBlur}
      aria-label={useTranslation('header::nav::label')}
      className={classNames(hasLowerDeck && navHasLowerDeck_)}
    >
      <div ref={navRef} className={upperDeck_}>
        <div className={deckContent_}>
          <div className={logoContainer_}>
            <Logo
              className={classNames(
                hasScrolledPastFirstSection && hasPrimaryCta && hideLogoMobile_
              )}
              renderCustomLogo={renderLogo}
            />
            {/* Mobile CTA that crossfades with the WS logo as a user scrolls below the first <Section> */}
            {hasPrimaryCta &&
              getCTALinkButton(primaryCta, {
                size: 'lg',
                className: classNames(mobileCta_, !hasScrolledPastFirstSection && hideMobileCta_),
                'data-testid': 'nav-primarycta-mobile-scrolled',
              })}
          </div>
          <div className={upperDeckCtaContainer_}>
            <div className={upperDeckCtaContainerContent_}>
              {hasSecondaryCta &&
                getCTALinkButton(secondaryCta, {
                  size: 'xl',
                  variant: 'secondary',
                  'data-testid': 'nav-secondarycta',
                  'data-cy': 'header-login-link',
                })}
              {hasPrimaryCta &&
                getCTALinkButton(primaryCta, {
                  size: 'xl',
                  'data-testid': 'nav-primarycta-desktop',
                  'data-cy': 'header-signup-link',
                })}
            </div>
          </div>
          {hasMobileNav && (
            <div className={mobileNavContainer_}>
              <MobileNav
                links={[...links, ...linksSecondary]}
                primaryCta={hasPrimaryCta && primaryCta}
                secondaryCta={hasSecondaryCta && secondaryCta}
                onOpen={() => {
                  setIsMobileNavOpen(true);
                }}
                onClose={() => {
                  setIsMobileNavOpen(false);
                }}
                activeIndex={mobileActiveIndex}
                onDropdownClick={handleDropdownClick}
              />
            </div>
          )}
        </div>
      </div>
      {hasLowerDeck && (
        <div ref={lowerDeckRef} className={classNames(lowerDeck_, isDesktopNavOpen && desktopNavOpen_)}>
          <div className={deckContent_}>
            <ul className={desktopNavLinksListPrimary_}>
              {links.map((item, index) => {
                const id = `nav-child-${index}`;
                return (
                  <li key={item.label} className={desktopInteractiveListItem_}>
                    {assignDesktopInteractionType(item, id, index)}
                  </li>
                );
              })}
            </ul>
            <ul className={classNames(desktopNavLinksListSecondary_)}>
              {linksSecondary?.map((item, index) => {
                const secondaryIndex = links.length + index;
                const id = `nav-child-${secondaryIndex}`;
                return (
                  <li className={desktopInteractiveListItem_} key={item.label}>
                    {assignDesktopInteractionType(item, id, secondaryIndex)}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}
      {children}
    </nav>
  );
};
