import React from 'react';
import { Markdown } from 'src/tapestry/core/markdown';
import { useTranslationContext, useTranslation } from 'src/components/contexts/TranslationContext';
import { RefreshGrid, RefreshCell, GRID_DEFAULT_CONFIG } from 'src/components/layout/Grid';
import { TLDRSubscribeForm } from 'src/components/common/TLDRSubscribeForm';
import { FluidText } from 'src/components/common/FluidText';
import { Image } from 'src/components/common/Image';
import { translations } from './TLDRFooter.translations';
import { tldrFooter, tldrLogo, tldrFooterLegalText_ } from './TLDRFooter.module.scss';
import tldr from './media/tldr.png';

const RENDERERS = {
  paragraph: ({ children }) => (
    <FluidText type="p" min="ws-text-md" max="ws-text-xl">
      {children}
    </FluidText>
  ),
};

export const TLDRFooter = () => {
  const { appendTranslationKeys } = useTranslationContext();
  appendTranslationKeys(translations);
  const footerSubtitle = useTranslation('footer::subtitle');

  return (
    <RefreshGrid
      xs={{ columnGap: 0 }}
      md={{ columnGap: GRID_DEFAULT_CONFIG.gap }}
      className={tldrFooter}
    >
      <RefreshCell all={{ width: 12 }} md={{ width: 4 }}>
        <Image className={tldrLogo} src={tldr} alt="" />
        <Markdown source={footerSubtitle} renderers={RENDERERS} />
      </RefreshCell>
      <RefreshCell all={{ width: 12 }} md={{ width: 7, left: 7 }}>
        <TLDRSubscribeForm
          buttonLabel={useTranslation('footer::button')}
          legalTextClassName={tldrFooterLegalText_}
        />
      </RefreshCell>
    </RefreshGrid>
  );
};
