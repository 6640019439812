import React from 'react';
import PropTypes from 'prop-types';
import { CTALinkButton } from 'src/components/common/CTALinkButton';
import { useDeeplinkUrl } from 'src/hooks/useDeeplinkUrl';
import { APP_DOWNLOAD_PATH, APP_DOWNLOAD_DEEPLINK } from 'src/utils/urls';
import { useUserAttributesContext } from 'src/components/contexts/UserAttributesContext';
import { useTranslationContext, useTranslation } from 'src/components/contexts/TranslationContext';
import { SIZE_TO_CLASS } from '../CTAButton';
import { translations } from './AppDownloadCTA.translations';

const BUTTON_SIZES = Object.keys(SIZE_TO_CLASS);

const AppDownloadCTA = ({
  size,
  mobileUrl,
  mobileLabel,
  nonMobileUrl,
  nonMobileLabel,
  ...restProps
}) => {
  const { appendTranslationKeys } = useTranslationContext();
  const { isMobileAppEligible } = useUserAttributesContext();
  appendTranslationKeys(translations);

  const mobileDeepLinkUrl = useDeeplinkUrl(APP_DOWNLOAD_DEEPLINK);
  const getStartedText = useTranslation('app_download_cta::label::signup');
  const downloadAppText = useTranslation('app_download_cta::label::download_app');

  // These override props are an escape hatch for one-off cases where we want to
  // preserve some but not all aspects of this component. If you find you're
  // overriding all 4 of these, don't. Instead, use CTALinkButton directly.
  const finalMobileUrl = mobileUrl ?? mobileDeepLinkUrl;
  const finalMobileLabel = mobileLabel ?? downloadAppText;
  const finalNonMobileUrl = nonMobileUrl ?? APP_DOWNLOAD_PATH;
  const finalNonMobileLabel = nonMobileLabel ?? getStartedText;

  if (isMobileAppEligible) {
    return (
      <CTALinkButton size={size} href={finalMobileUrl} {...restProps} data-testid="mobile-signup-cta">
        {finalMobileLabel}
      </CTALinkButton>
    );
  }

  return (
    <CTALinkButton
      size={size}
      href={finalNonMobileUrl}
      {...restProps}
      data-testid="desktop-signup-cta"
    >
      {finalNonMobileLabel}
    </CTALinkButton>
  );
};

AppDownloadCTA.propTypes = {
  size: PropTypes.oneOf(BUTTON_SIZES),
  mobileUrl: PropTypes.string,
  mobileLabel: PropTypes.node,
  nonMobileUrl: PropTypes.string,
  nonMobileLabel: PropTypes.node,
};

export { AppDownloadCTA, BUTTON_SIZES };
