import React, { useEffect, useState } from 'react';
import { classNames } from 'src/utils/css';
import { SmartLink } from 'src/tapestry/core/links/smart-link';
import { useComponentContext } from 'src/components/layout/ComponentContext';
import { useMotionOptOutContext } from 'src/components/contexts/MotionOptOutContext';
import { trackAnalyticsEvent, EVENT_NAMES } from 'src/services/analytics-service';
import { FluidText } from 'src/components/common/FluidText';
import { ArrowLinkButton } from 'src/components/common/ArrowLinkButton';
import { NavLink } from '../NavLink/NavLink.component';
import {
  dropdownButtonContainer_,
  dropdownButton_,
  dropDownButtonOpen_,
  dropdownButtonLabel_,
  drawerContainer_,
  drawerContent_,
  categoryContainer_,
  categorySummary_,
  categoryColumnsContainer_,
  categoryColumnsContent_,
  categoryColumn_,
  columnLinkList_,
  drawerOpen_,
  drawerClose_,
  focusContainer_,
  categoryLinkWrapper_,
  categoryBackground_,
  reducedAnimation_,
  caret_,
  caretOpen_,
  caretClosed_,
  categoryCta_,
} from './NavDropdown.module.scss';

const NavDropdown = ({
  label,
  categoryHeading,
  categorySubheading,
  categoryHref,
  categoryColumns,
  isOpen,
  id,
  ...props
}) => {
  const componentContext = useComponentContext();
  if (isOpen) {
    trackAnalyticsEvent(EVENT_NAMES.NAV_DROPDOWN_OPENED, { label }, componentContext);
  }

  const { prefersReducedMotion } = useMotionOptOutContext();

  const [isActivated, setisActivated] = useState(false);

  // how we get 3(initial, open & close) states from a 2(open & close) state "isOpen" variable
  useEffect(() => {
    if (!isActivated && isOpen) {
      setisActivated(isOpen);
    }
  }, [setisActivated, isOpen, isActivated]);

  const [interactionStateActive, setInteractionStateActive] = useState(false);
  const handleActive = () => {
    setInteractionStateActive(true);
  };

  const handleInactive = () => {
    setInteractionStateActive(false);
  };

  return (
    <>
      <div className={classNames(dropdownButtonContainer_)}>
        <button
          type="button"
          className={classNames(
            'ws-text-lg-medium',
            dropdownButton_,
            isOpen && dropDownButtonOpen_
          )}
          aria-controls={id}
          aria-expanded={isOpen}
          {...props}
        >
          <span className={dropdownButtonLabel_}>{label}</span>
          <span
            className={classNames(caret_, isActivated && (isOpen ? caretOpen_ : caretClosed_))}
            aria-hidden="true"
          >
            <span />
            <span />
          </span>
        </button>
      </div>
      <div
        className={classNames(
          drawerContainer_,
          isActivated && (isOpen ? drawerOpen_ : drawerClose_),
          prefersReducedMotion && reducedAnimation_
        )}
        data-testid="subnav-drawer-bg"
        id={id}
      >
        <div
          className={classNames(
            drawerContent_,
            isActivated && (isOpen ? drawerOpen_ : drawerClose_),
            prefersReducedMotion && reducedAnimation_
          )}
        >
          <div className={categoryContainer_}>
            <SmartLink
              href={categoryHref}
              disabled={!categoryHref}
              onMouseEnter={handleActive}
              onMouseLeave={handleInactive}
              onFocus={handleActive}
              onBlur={handleInactive}
              className={categoryLinkWrapper_}
            >
              <div className={categoryBackground_} />
              <div className={categorySummary_}>
                <div className={focusContainer_}>
                  <FluidText
                    type="h2"
                    all="ws-display-md"
                    data-testid="subnav-drawer-category-heading"
                  >
                    {categoryHeading}
                  </FluidText>
                  <FluidText type="p" all="ws-text-xl">
                    {categorySubheading}
                  </FluidText>
                  {categoryHref && (
                    <ArrowLinkButton
                      className={categoryCta_}
                      isActive={interactionStateActive}
                    />
                  )}
                </div>
              </div>
            </SmartLink>
            <div className={categoryColumnsContainer_}>
              <div className={categoryColumnsContent_}>
                {categoryColumns?.map(column => (
                  <div key={`desktop-nav-column-${column.heading}`} className={categoryColumn_}>
                    <FluidText all="ws-eyebrow-sm">{column.heading}</FluidText>
                    <ul className={columnLinkList_}>
                      {column.links?.map(link => {
                        return (
                          <li key={`desktop-nav-column-link-${link.label}`}>
                            <NavLink parentLabel={column.heading} href={link.href} secondary>
                              {link.label}
                            </NavLink>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { NavDropdown };
