'use client';

import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'src/utils/css';
import { SmartLink } from 'src/tapestry/core/links/smart-link';
import { EVENT_NAMES } from 'src/services/analytics-service';
import { useComponentContext } from 'src/components/layout/ComponentContext';
import { getLinkThemeClass, ButtonLabel, SIZE_TO_CLASS, VARIANT_TO_CLASS, ICON_POSITION_TO_CLASS } from 'src/components/common/CTAButton';

export const CTALinkButton = ({
  variant = 'primary',
  size,
  onClick,
  children,
  className,
  href,
  theme = 'none',
  icon,
  iconPosition = 'right',
  ...rest
}) => {
  const { shouldUseDarkTheme } = useComponentContext();
  const themeClassName = getLinkThemeClass(theme, shouldUseDarkTheme);
  const finalSize = size ?? (variant === 'secondary' ? 'xl' : 'xxl');

  return (
    <SmartLink
      className={classNames(
        VARIANT_TO_CLASS[variant],
        SIZE_TO_CLASS[finalSize],
        ICON_POSITION_TO_CLASS[iconPosition],
        themeClassName,
        className
      )}
      href={href}
      onClick={onClick}
      eventData={{
        name: EVENT_NAMES.PRIMARY_CTA_CLICKED,
        label: children,
      }}
      {...rest}
    >
      <ButtonLabel icon={icon} iconPosition={iconPosition}>{children}</ButtonLabel>
    </SmartLink>
  );
};

CTALinkButton.propTypes = {
  variant: PropTypes.oneOf(Object.keys(VARIANT_TO_CLASS)),
  size: PropTypes.oneOf(Object.keys(SIZE_TO_CLASS)),
  onClick: PropTypes.func,
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
  theme: PropTypes.oneOf(['none', 'dark', 'light']),
  icon: PropTypes.node,
  iconPosition: PropTypes.oneOf(Object.keys(ICON_POSITION_TO_CLASS)),
};
