import React, { forwardRef } from 'react';
import { classNames } from 'src/utils/css';
import { useHeaderContext } from 'src/components/layout/Header';
import { useTranslation } from 'src/components/contexts/TranslationContext';
import {
  hamburgerButton,
  topLine,
  topLineOpen,
  bottomLine,
  bottomLineOpen,
  linesContainer,
} from './HamburgerButton.module.scss';

export const HamburgerButton = forwardRef(function HamburgerButton(
  { className, onClick, ...restProps },
  ref
) {
  const { isMobileNavOpen } = useHeaderContext();

  return (
    <button
      ref={ref}
      className={classNames(hamburgerButton, className)}
      type="button"
      aria-expanded={isMobileNavOpen}
      onClick={onClick}
      {...restProps}
    >
      <span aria-hidden="true" className={linesContainer}>
        <span className={classNames(topLine, isMobileNavOpen && topLineOpen)} />
        <span className={classNames(bottomLine, isMobileNavOpen && bottomLineOpen)} />
      </span>
      <span className="visually-hidden">{useTranslation('header::cta::menu_toggle')}</span>
    </button>
  );
});
