import React, { useRef, useState } from 'react';
import { classNames } from 'src/utils/css';
import { useTrapFocus } from 'src/hooks/useTrapFocus';
import { COMPONENT_NAMES, EVENT_NAMES, trackAnalyticsEvent } from 'src/services/analytics-service';
import { ComponentContextProvider } from 'src/components/layout/ComponentContext';
import { getCTALinkButton } from 'src/components/common/CTALinkButton';
import { Logo } from 'src/components/common/Logo/Logo.component';
import { useDisableScroll } from 'src/hooks/useDisableScroll';
import { MobileNavLink } from '../MobileNavLink/MobileNavLink.component';
import { MobileNavCategoryDrawer } from '../MobileNavCategoryDrawer/MobileNavCategoryDrawer.component';
import { useHeaderContext } from '../../../HeaderContext.component';
import {
  drawer_,
  drawerOpen_,
  drawerClose_,
  drawerHeader_,
  drawerBody_,
  linkList_,
  linkItem_,
  drawerFooter_,
  drawerCta_,
} from './MobileNav.module.scss';
import { HamburgerButton } from '../HamburgerButton/HamburgerButton.component';

const MobileNav = ({ primaryCta, secondaryCta, links, activeIndex, onDropdownClick }) => {
  const { isMobileNavOpen, setIsMobileNavOpen } = useHeaderContext();
  const burgerOpenRef = useRef();
  const drawerRef = useRef();
  const [resetNav, setResetNav] = useState(false);

  // Prevents body scroll while the drawer is open
  useDisableScroll(isMobileNavOpen);

  useTrapFocus({
    element: drawerRef.current,
    shouldTrap: isMobileNavOpen,
    initialFocusElement: burgerOpenRef.current,
  });
  const [isActivated, setisActivated] = useState(false);

  const toggleMenu = () => {
    if (isMobileNavOpen) {
      setIsMobileNavOpen(false);
    } else {
      trackAnalyticsEvent(EVENT_NAMES.NAV_MENU_DRAWER_OPENED, {
        component_name: COMPONENT_NAMES.MOBILE_NAV,
      });

      if (!isActivated) {
        setisActivated(true);
      }
      setIsMobileNavOpen(true);
    }
    setResetNav(true);
  };

  const handleCategoryClick = e => {
    onDropdownClick(e);
    setResetNav(false);
  };

  const navDrawerId = 'MainMobileNavDrawer';

  return (
    <ComponentContextProvider
      value={{
        name: COMPONENT_NAMES.MOBILE_NAV,
      }}
    >
      <HamburgerButton
        onClick={toggleMenu}
        aria-controls={navDrawerId}
        data-testid="nav-burger-closed"
      />
      <div
        id={navDrawerId}
        tabIndex="-1"
        aria-hidden={!isMobileNavOpen}
        ref={drawerRef}
        className={classNames(
          drawer_,
          isActivated && (isMobileNavOpen ? drawerOpen_ : drawerClose_)
        )}
        data-testid="mobile-nav-drawer"
      >
        <div className={drawerHeader_}>
          <Logo />
          <HamburgerButton
            onClick={toggleMenu}
            ref={burgerOpenRef}
            aria-controls={navDrawerId}
            data-testid="nav-burger-open"
          />
        </div>
        <div className={drawerBody_}>
          <ul className={linkList_}>
            {links.map((link, index) => {
              const childId = `mobile-nav-child-${index}`;
              return (
                <li
                  className={linkItem_}
                  key={typeof link.label === 'string' ? link.label : `mobileNavLink-${index}`}
                >
                  {link.categories ? (
                    <MobileNavCategoryDrawer
                      label={link.label}
                      categories={link.categories}
                      data-mobilenavindex={index}
                      id={childId}
                      isOpen={activeIndex === index && !resetNav}
                      onClick={handleCategoryClick}
                    />
                  ) : (
                    <MobileNavLink href={link.href}>{link.label}</MobileNavLink>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
        <div className={drawerFooter_}>
          {secondaryCta &&
              getCTALinkButton(secondaryCta, {
                className: drawerCta_,
                variant: 'secondary',
                size: 'lg',
              })}
          {primaryCta &&
              getCTALinkButton(primaryCta, {
                variant: 'primary',
                size: 'lg',
                className: drawerCta_,
              })}
        </div>
      </div>
    </ComponentContextProvider>
  );
};

export { MobileNav };
