export const CONTENTFUL_PAGE_TYPES = {
  LANDING_PAGE: 'landingPage',
  STANDARD_PAGE: 'pageBase',
  PRODUCT_DETAIL_PAGE: 'page_productDetail',
  ACCOUNT_PAGE: 'page_account',
  LEARN_ARTICLE_PAGE: 'learnArticlePage',
};

export const CONTENTFUL_SECTIONS = {
  FEATURE_CARD: 'section_featureCard',
  KEY_POINTS_VERTICAL: 'keyPointsVertical',
  KEY_STATS: 'keyStats',
  SPECIAL_CONTENT: 'section_specialContent',
  PROMOTIONAL: 'promotionalSection',
  TABLE: 'tableSection',
  MEDIA_WITH_DESCRIPTION: 'imageWithText',
  TIER_CARDS: 'tierCardsSection',
};

export const BACKGROUND_COLOR_OPTIONS = [
  { label: 'None', value: '', backgroundColor: '#FFFFFF', colors: ['#FFFFFF'] },
  { label: 'Slate/95', value: '#F1F0F0', backgroundColor: '#F1F0F0', colors: ['#F1F0F0'] },
  { label: 'SoftBlack', value: '#32302F', colors: ['#32302F'], backgroundColor: '#32302F' },
  { label: 'Chartreuse/Foreground/95', value: '#EFF6D1', colors: ['#EFF6D1'], backgroundColor: '#EFF6D1' },
  { label: 'Chartreuse/Background/90', value: '#DCE8BB', colors: ['#DCE8BB'], backgroundColor: '#DCE8BB' },
  { label: 'Sky/Foreground/95', value: '#E9F2F9', colors: ['#E9F2F9'], backgroundColor: '#E9F2F9' },
  { label: 'Sky/Background/90', value: '#d3e5f3', colors: ['#d3e5f3'], backgroundColor: '#d3e5f3' },
  { label: 'Lilac/Foreground/95', value: '#F7EEF7', colors: ['#F7EEF7'], backgroundColor: '#F7EEF7' },
  { label: 'Lilac/Background/90', value: '#E8D9EE', colors: ['#E8D9EE'], backgroundColor: '#E8D9EE' },
  { label: 'Aubergine/Foreground/95', value: '#F8EEF2', colors: ['#F8EEF2'], backgroundColor: '#F8EEF2' },
  { label: 'Aubergine/Background/90', value: '#E8DFE5', colors: ['#E8DFE5'], backgroundColor: '#E8DFE5' },
  { label: 'Apricot/Foreground/95', value: '#FFEEE2', colors: ['#FFEEE2'], backgroundColor: '#FFEEE2' },
  { label: 'Apricot/Background/90', value: '#F8DDCC', colors: ['#F8DDCC'], backgroundColor: '#F8DDCC' },
  { label: 'Terracotta/Foreground/95', value: '#FFEDEB', colors: ['#FFEDEB'], backgroundColor: '#FFEDEB' },
  { label: 'Terracotta/Background/90', value: '#F7DDD9', colors: ['#F7DDD9'], backgroundColor: '#F7DDD9' },
  { label: 'Ocean/Light', value: '#DDE8EE', colors: ['#DDE8EE'], backgroundColor: '#DDE8EE' },
  { label: 'Ocean/Medium', value: '#A8BAC2', colors: ['#A8BAC2'], backgroundColor: '#A8BAC2' },
  { label: 'Blush/Light', value: '#F9F2EF', colors: ['#F9F2EF'], backgroundColor: '#F9F2EF' },
  { label: 'Blush/Medium', value: '#E8DFDC', colors: ['#E8DFDC'], backgroundColor: '#E8DFDC' },
  { label: 'Sand/Light', value: '#E1DCD0', colors: ['#E1DCD0'], backgroundColor: '#E1DCD0' },
  { label: 'Sand/Medium', value: '#D0BD88', colors: ['#D0BD88'], backgroundColor: '#D0BD88' },
  { label: 'Grass/Light', value: '#E4E9D3', colors: ['#E4E9D3'], backgroundColor: '#E4E9D3' },
  { label: 'Grass/Medium', value: '#9FAA75', colors: ['#9FAA75'], backgroundColor: '#9FAA75' },
  { label: 'Premium/LightLinen', value: '#FAF8F5', colors: ['#FAF8F5'], backgroundColor: '#FAF8F5' },
  { label: 'Premium/Linen', value: '#EEECE7', colors: ['#EEECE7'], backgroundColor: '#EEECE7' },
  { label: 'Generation/Navy', value: '#2A2C32', colors: ['#2A2C32'], backgroundColor: '#2A2C32' },
  { label: 'Generation/Midnight', value: '#09090A', colors: ['#09090A'], backgroundColor: '#09090A' },
];
