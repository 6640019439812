import { DEFAULT_LOCALE, isEquivalentLocale } from '../localization';

const getSectionByType = (content, componentType) => {
  const contentToSearch = content?.items ?? content;
  return contentToSearch.find(block => block.__typename === componentType);
};

const getSectionByName = (content, sectionName) => {
  const contentToSearch = content?.items ?? content;
  return contentToSearch.find(block => block.sectionName === sectionName);
};

/**
 * @deprecated Used only in Gatsby. For Next.js, use get getFAQ instead.
 *
 * Given the result of a FAQ fragment, will return an object with shape:
 *  {
 *     id:          string,
 *     heading:     string,
 *     subheading:  string,
 *     faqs: {Array} [{
 *       question: string,
 *       answer: string,
 *       id: string,
 *     }]
 *  }
 * and where the resulting object can be passed in directly to an <FAQ> component as props
 * @param {object} The object returned from a FAQ fragment queried on the content field of ContentfulStandardPage
 * @returns {object} An object id, heading, subheading and faqs
 *
 * There is also a case where we need to inject Conversion Landing Page FAQ's directly in here
 * In this case, we directly set faqNode as content
 */
const getFAQGatsby = content => {
  if (!content) return null;

  let faqNode = content;

  if (Array.isArray(content)) {
    faqNode = content.find(
      contentfulNode => contentfulNode?.__typename === 'ContentfulFaqQuestions'
    );
  }

  if (!faqNode || !faqNode.questions) return null;
  return {
    heading: faqNode.heading,
    id: faqNode.contentful_id,
    entryId: faqNode.contentful_id,
    faqs: faqNode.questions
      .filter(questionAnswerObj => questionAnswerObj.question && questionAnswerObj.answer?.answer)
      .filter(
        questionAnswerObj =>
          !questionAnswerObj.localesOmitted?.includes(
            questionAnswerObj.node_locale.replace(/[-_]/, '')
          )
      )
      .map(questionAnswerObj => ({
        question: questionAnswerObj.question,
        answer: questionAnswerObj.answer.answer,
        id: questionAnswerObj.questionId,
        entryId: questionAnswerObj.contentful_id,
      })),
  };
};

/**
 * Given the result of a StandardPageSeoContent fragment, will return an object with shape:
 *  {
 *     metadata: {Object},
 *     faq: [Array]
 *  }
 * where the metadata can be passed directly as the metadata prop to PageLayout/Layout,
 * and the faq key can be spread in directly to an <FAQ> component
 * @param {object} The object returned from a StandardPageSeoContent fragment queried on StandardPage
 * @param {string} The locale used to query the data
 * @returns {object} An object with metadata and faq keys
 */
const getFAQ = (content, locale = DEFAULT_LOCALE) => {
  if (!content) return null;

  let faqNode = null;

  if (!content?.__typename || content.__typename === 'FaqQuestions') {
    faqNode = content;
  } else if (content.__typename === 'PageBaseContentCollection') {
    faqNode = content.items.find(node => node.__typename === 'FaqQuestions');
  }

  if (faqNode == null) {
    return faqNode;
  }

  if (faqNode.questions == null) {
    return null;
  }

  const entryId = faqNode?.sys?.id || content?.entryId;

  const filterQuestions = questionAnswerObj => {
    const hasQuestionAndAnswer = questionAnswerObj.question && questionAnswerObj.answer;
    const isNotOmitted = questionAnswerObj.localesOmitted === null
      ? true
      : !questionAnswerObj.localesOmitted.some(localeOmitted => isEquivalentLocale(locale, localeOmitted));
    return hasQuestionAndAnswer && isNotOmitted;
  };

  const formatQuestions = questionAnswerObj => ({
    question: questionAnswerObj.question,
    answer: questionAnswerObj.answer,
    id: questionAnswerObj.questionId,
    entryId: questionAnswerObj.sys.id,
  });

  const result = {
    heading: faqNode.heading,
    id: entryId,
    entryId,
    faqs: faqNode.questions.items
      .filter(filterQuestions)
      .map(formatQuestions),
  };

  return result;
};

/**
 * @deprecated Used only in Gatsby. For Next.js, use get getSeoOnlyContent instead.
 *
 * Given the result of a StandardPageSeoContent fragment, will return an object with shape:
 *  {
 *     metadata: {Object},
 *     faq: [Array]
 *  }
 * where the metadata can be passed directly as the metadata prop to PageLayout/Layout,
 * and the faq key can be spread in directly to an <FAQ> component
 * @param {object} The object returned from a StandardPageSeoContent fragment queried on StandardPage
 * @returns {object} An object with metadata and faq keys
 */
const getSeoOnlyContentGatsby = (pageData = {}) => {
  return {
    metadata: pageData?.metadata ?? null,
    faqs: getFAQGatsby(pageData?.content) ?? null,
  };
};

/**
 * Given the result of a StandardPageSeoContent fragment, will return an object with shape:
 *  {
 *     metadata: {Object},
 *     faq: [Array]
 *  }
 * where the metadata can be passed directly as the metadata prop to PageLayout/Layout,
 * and the faq key can be spread in directly to an <FAQ> component
 * @param {object} The object returned from a StandardPageSeoContent fragment queried on StandardPage
 * @returns {object} An object with metadata and faq keys
 */
const getSeoOnlyContent = (pageData = {}) => {
  return {
    metadata: pageData?.metadata ?? null,
    faqs: getFAQ(pageData?.content) ?? null,
  };
};

const getCollectionContent = content => {
  if (content.items.length === 0) return null;

  let collectionContentNode = content;

  if (Array.isArray(content.items)) {
    collectionContentNode = content.items.filter(
      contentfulNode => contentfulNode?.__typename === 'Collection'
    );
  }

  if (!collectionContentNode) return null;

  return collectionContentNode.reduce((obj, collection) => {
    const { sectionName } = collection;

    return {
      ...obj,
      [sectionName]: collection,
    };
  }, {});
};

const getCollectionContentBySectionName = (sectionName, content) => {
  const collectionContent = getCollectionContent(content);

  if (!collectionContent || !sectionName) return null;

  return collectionContent[sectionName];
};

export {
  getSectionByType,
  getSectionByName,
  getFAQ,
  getFAQGatsby,
  getSeoOnlyContent,
  getSeoOnlyContentGatsby,
  getCollectionContentBySectionName,
};
