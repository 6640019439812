import { useEffect } from 'react';
import { scrollDisabled_ } from './useDisableScroll.module.scss';

const disableScroll = () => {
  document.body.classList.add(scrollDisabled_);
};

const enableScroll = () => {
  document.body.classList.remove(scrollDisabled_);
};

export const useDisableScroll = trigger => {
  useEffect(() => {
    if (trigger) {
      disableScroll();
    } else {
      enableScroll();
    }

    return () => {
      enableScroll();
    };
  }, [trigger]);
};
