import React, { useId } from 'react';
import PropTypes from 'prop-types';
import { SmartLink } from 'src/tapestry/core/links/smart-link';
import { classNames } from 'src/utils/css';
import { ArrowRight } from 'src/components/common/Icons';
import { toXPathData } from 'src/utils/netlify-create/xpath';
import {
  wrapper_,
  animateOnHover_,
  innerContent_,
  innerContentTransparent_,
  backgroundLeft_,
  backgroundRight_,
  backgroundMiddle_,
  arrowExtender_,
  arrowExtenderTransparentBackground_,
  arrow_,
  arrowLeft_,
  arrowTransparent_,
  svg_,
  background_,
  leftToRight_,
  rightToLeft_,
  active_,
} from './ArrowLinkButton.module.scss';

export const ANIMATION_DIRECTIONS = {
  LEFT_TO_RIGHT: {
    className: leftToRight_,
  },
  RIGHT_TO_LEFT: {
    className: rightToLeft_,
  },
};

export const ARROW_DIRECTIONS = {
  LEFT: {
    className: arrowLeft_,
  },
  RIGHT: {
    className: '',
  },
};

const Wrapper = ({ href, onClick, ...rest }) => {
  let Component = 'div';
  if (href) Component = SmartLink;
  if (!href && onClick) Component = 'button';

  const props = {
    ...(Component === 'button' ? { type: 'button' } : {}),
    href,
    onClick,
    ...rest
  };

  return <Component {...props} />;
};

export const ArrowLinkButton = ({
  href,
  className,
  arrowDirection = ARROW_DIRECTIONS.RIGHT,
  animationDirection = ANIMATION_DIRECTIONS.LEFT_TO_RIGHT,
  isStandalone = false,
  isActive = false,
  transparentBackground,
  onClick,
  ...rest
}) => {
  const uuid = useId();
  const backgroundClass = !transparentBackground && background_;
  const arrowClass = transparentBackground ? arrowTransparent_ : arrow_;

  return (
    <Wrapper
      className={classNames(wrapper_, isStandalone && animateOnHover_, isActive && active_, arrowDirection.className, className)}
      href={href}
      onClick={onClick}
      {...rest}
      {...toXPathData('ArrowLinkButton_href')}
    >
      <div
        className={classNames(
          innerContent_,
          transparentBackground && innerContentTransparent_,
          animationDirection.className,
          arrowClass
        )}
      >
        <div className={classNames(backgroundLeft_, backgroundClass)} />
        <div className={classNames(backgroundRight_, backgroundClass)} />
        <div className={classNames(backgroundMiddle_, backgroundClass)} />
        <div
          className={classNames(
            arrowExtender_,
            transparentBackground && arrowExtenderTransparentBackground_
          )}
        />
        <ArrowRight id={uuid} className={svg_} />
      </div>
    </Wrapper>
  );
};

ArrowLinkButton.propTypes = {
  href: PropTypes.string,
  className: PropTypes.string,
  arrowDirection: PropTypes.oneOf([ARROW_DIRECTIONS.LEFT, ARROW_DIRECTIONS.RIGHT]),
  animationDirection: PropTypes.oneOf([ANIMATION_DIRECTIONS.LEFT_TO_RIGHT, ANIMATION_DIRECTIONS.RIGHT_TO_LEFT]),
  isActive: PropTypes.bool,
  // component assumes it is a child of a <Card>. if not, set this to `true`.
  isStandalone: PropTypes.bool,
  transparentBackground: PropTypes.bool,
};
